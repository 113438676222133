import css from 'styled-jsx/css';
import typography from '../../../../styles/typography';

export const styles = css`
    .NowViewBadge {
      ${typography.h9};
      display: flex;
      justify-content: center;
      pointer-events: all;
      border-radius: 32px;
      width: 49px;
      height: 26px;
      padding: 4px 0;
      box-sizing: border-box;
      margin-bottom: 5px;
      background-color: var(--colors-frostedMint);
      border: 1.5px solid var(--colors-white);
      color: var(--colors-text);
      text-align: center;
    }

    .NowViewBadge__icon {
      display: inline-block;
      margin-right: 7px;
      line-height: 18px;
      color: var(--colors-zakazGreen);
    }

    .NowViewBadge__text {
      font-weight: 600;
    }

    .NowViewBadge:hover .NowViewBadge__text {
      color: var(--colors-zakazGreen);
    }
  `;
