import cn from 'classnames';
import { styles } from './Badge.styles';

type BadgeType = 'bulk' | 'novelty' | 'hit' | 'bundle' | 'promo' | 'discount' | 'ownBrand';
export type BadgeSize = 'default' | 'small' | 'micro';

export interface BadgeProps {
  type: BadgeType;
  straightLeft?: boolean;
  text?: string;
  dataMarkerValue?: string;
  imgSrc?: string | undefined;
  size?: BadgeSize;
}

const Badge = (props: BadgeProps) => {
  const {
    type,
    size = 'default',
    imgSrc,
    straightLeft,
    dataMarkerValue,
    text,
  } = props;
  return (
    <div
      className={cn(
        'Badge',
        `Badge_${type}`,
        {
          [`Badge_${size}`]: size !== 'default',
          'Badge_straightLeft': straightLeft,
        })}
      data-testid='productBadge'
      data-marker={dataMarkerValue}
    >
      {
        imgSrc
          ? <img src={imgSrc} alt='badge' className='Badge__logo' />
          : <span className='Badge__text'>{text}</span>
      }


      <style jsx>{styles}</style>
    </div>
  );
};

export default Badge;
