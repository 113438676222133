
import { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import useLocalization from 'src/services/localization/useLocalization';
import { styles } from './NowViewBadge.styles';

interface Props {
  views: number;
}

const NowViewBadge = (props: Props) => {
  const { views } = props;
  const localize = useLocalization();

  return (
    <Fragment>
      <div
        className='NowViewBadge'
        data-tip={`${localize('now_watching')}: ${views}`}
        data-for='views-eye'
        data-marker='Views Tooltip'
        data-testid='views-tooltip'
      >
        <span className='NowViewBadge__icon icon-eye' data-testid='views-tooltip-icon' />
        <span className='NowViewBadge__text'>{views}</span>
      </div>
      <ReactTooltip
        id='views-eye'
        effect='solid'
        getContent={(content) => <span data-marker='Views Tooltip Content'>{content}</span>}
      />
      <style jsx>{styles}</style>
    </Fragment>
  );
};

export default NowViewBadge;
