import cn from 'classnames';
import { BadgesLocalizationsType } from 'src/utils/product/getBadgesLocalizations';
import Badge, { BadgeProps, BadgeSize } from './Badge/Badge';
import { styles } from "./Badges.styles";
import NowViewBadge from './NowViewBadge/NowViewBadge';

interface BadgesProps extends Partial<BadgesLocalizationsType> {
  straightLeft?: boolean;
  promo?: string;
  bulk?: string;
  views?: number;
  withOpacity?: boolean;
  size?: BadgeSize;
}

const Badges = (props: BadgesProps) => {
  const {
    straightLeft,
    size,
    hit,
    novelty,
    bundle,
    discount,
    ownBrandLogo,
    withOpacity,
    promo,
    views,
    bulk,
  } = props;

  const commonProps: Partial<BadgeProps> = {
    straightLeft,
    size,
  };

  return (
    <div className={cn('Badges', { Badges_withOpacity: withOpacity })}>
      {discount && (
        <Badge
          text={discount}
          type='discount'
          dataMarkerValue='Discount'
          {...commonProps}
        />
      )}

      {bulk && (
        <Badge
          text={bulk}
          type='bulk'
          dataMarkerValue='Bulk'
          {...commonProps}
        />
      )}

      {bundle && (
        <Badge
          text={bundle}
          type='bundle'
          dataMarkerValue='Bundle'
          {...commonProps}
        />
      )}

      {ownBrandLogo && (
        <Badge
          type='ownBrand'
          imgSrc={ownBrandLogo}
          size={size}
          dataMarkerValue='ownBrand'
        />
      )}

      {novelty &&
        <Badge
          text={novelty}
          type='novelty'
          dataMarkerValue='New'
          {...commonProps}
        />
      }

      {hit && (
        <Badge
          text={hit}
          type='hit'
          dataMarkerValue='Hit'
          {...commonProps}
        />
      )}

      {!!views && (
        <NowViewBadge views={views} />
      )}

      {promo && (
        <Badge
          text={promo}
          type='promo'
          dataMarkerValue='Promo'
          {...commonProps}
        />
      )}

      <style jsx>{styles}</style>
    </div>
  );
};

export default Badges;
