import css from 'styled-jsx/css';

export const styles = css`
  .Badge {
    pointer-events: none;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    line-height: normal;
    border-radius: 32px;
    border: 1.5px solid var(--colors-white);
    width: 58px;
    height: 29px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
  }
  .Badge_promo {
    width: 118px;
    justify-content: flex-start;
    padding-left: 8px;
  }
  .Badge_discount,
  .Badge_promo {
    background-color: var(--colors-red);
    color: var(--colors-white);
  }
  .Badge_novelty {
    background-color: var(--colors-orange);
    color: var(--colors-text);
  }
  .Badge_hit {
    background-color: var(--colors-orange);
    color: var(--colors-capeCod);
    letter-spacing: 1.1px;
    text-transform: uppercase;
  }
  .Badge_bulk {
    background-color: var(--colors-zakazGreen);
    color: var(--colors-white);
  }
  .Badge_bundle {
    background-color: #39b54a;
    color: var(--colors-white);
    text-transform: lowercase;
  }
  .Badge_straightLeft {
    width: 50px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
  .Badge_small {
    width: 41px;
    height: 18px;
    border-width: 1px;
    margin-bottom: 4px;
    font-size: 10px;
    line-height: 10px;
  }
  .Badge_novelty .Badge__text {
    margin: 0 9px;
  }
  .Badge_novelty {
    width: 100%;
  }
  .Badge_ownBrand {
    width: 32px;
    height: 32px;
    border: none;
    margin-left: 8px;
  }
  .Badge_ownBrand.Badge_small {
    height: 24px;
    width: 24px;
    margin-left: 0;
  }
  .Badge_ownBrand.Badge_micro {
    height: 16px;
    width: 16px;
    margin-left: 0;
  }
  .Badge__logo {
    height: 100%;
    width: auto;
  }
`;
